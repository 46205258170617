import React from "react";
import classes from "./Benefits.module.scss";
import img1 from "../../../assets/img/QuickStart/2/1.png";

import { useTranslation } from "react-i18next";

const Benefits = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.benefits}>
      <div className={classes.benefitsBody}>
        <div className="container">
          <div className={[classes.benefitsTitle, "font-48"].join(" ")}>
            {t("qucikstart_4_item1")}
          </div>
          <div className={classes.benefitsRow}>
            <div className={classes.benefitsRowLeft}>
              <div className={classes.benefitsRowLeftList}>
                <div
                  className={[classes.benefitsRowLeftListItem, "font-24"].join(
                    " "
                  )}
                >
                  {t("qucikstart_4_item2")}
                </div>
                <div
                  className={[classes.benefitsRowLeftListItem, "font-24"].join(
                    " "
                  )}
                >
                  {t("qucikstart_4_item3")}
                </div>
                <div
                  className={[classes.benefitsRowLeftListItem, "font-24"].join(
                    " "
                  )}
                >
                  {t("qucikstart_4_item4")}
                </div>
              </div>
            </div>
            <div className={classes.benefitsRowRight}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
