import React from "react";
import classes from "./ETFs.module.scss";
import icon1 from "../../../../assets/img/InvestmentChoices/markets/efts-icon-1.png";
import icon2 from "../../../../assets/img/InvestmentChoices/markets/efts-icon-2.png";
import icon3 from "../../../../assets/img/InvestmentChoices/markets/efts-icon-3.png";
import icon4 from "../../../../assets/img/InvestmentChoices/markets/efts-icon-4.png";
import { useTranslation } from "react-i18next";

const ETFs = ({ currentTab }) => {
  const { t } = useTranslation();
  return (
    <section
      className={`${classes.etfsWrap} ${"etfs" === currentTab && classes.tabActive}`}
      id="markets-efts"
    >
      <h3 className="stocksProgressBarTitle">{t("investment_6_item11")}</h3>
      <h4 className={[classes.etfsTitle, "font-32 mb-0"].join(" ")}>
        {t("investment_6_item12")}
      </h4>
      <h4 className={[classes.etfsTitle, "font-32 mb-20"].join(" ")}>
        {t("investment_6_item122")}
      </h4>
      <p className={[classes.etfsText, "font-24 fw-light mb50"].join(" ")}>
        {t("investment_6_item13")}
      </p>
      <h4 className={[classes.etfsTitle, "font-32 text-center"].join(" ")}>
        {t("investment_6_bot_title")}
      </h4>
      <h3 className={[classes.etfsSubTitle, "font-36"].join(" ")}>{t("")}</h3>
      <div className={classes.etfsGridRow}>
        <div className={[classes.etfsGridHalf, "market-col-6"].join(" ")}>
          <div
            className={[
              classes.etfsGridHalfWrap,
              classes.borderBottom,
              classes.borderRight,
            ].join(" ")}
          >
            <img src={icon1} className={classes.etfsGridHalfImg} alt=''/>
            <h5 className={[classes.etfsGridHalfTitle, "font-32"].join(" ")}>
              {t("investment_6_bot_row11")}
            </h5>
            <p className={[classes.etfsGridHalfText, "font-20"].join(" ")}>
              {t("investment_6_bot_row12")}
            </p>
          </div>
        </div>
        <div className={[classes.etfsGridHalf, "market-col-6"].join(" ")}>
          <div className={[classes.etfsGridHalfWrap, classes.borderBottom].join(" ")}>
            <img src={icon2} className={classes.etfsGridHalfImg} alt=''/>
            <h5 className={[classes.etfsGridHalfTitle, "font-32"].join(" ")}>
              {t("investment_6_bot_row21")}
            </h5>
            <p className={[classes.etfsGridHalfText, "font-20"].join(" ")}>
              {t("investment_6_bot_row22")}
            </p>
          </div>
        </div>
        <div className={[classes.etfsGridHalf, "market-col-6"].join(" ")}>
          <div className={[classes.etfsGridHalfWrap, classes.borderRight].join(" ")}>
            <img src={icon3} className={classes.etfsGridHalfImg} alt=''/>
            <h5 className={[classes.etfsGridHalfTitle, "font-32"].join(" ")}>
              {t("investment_6_bot_row31")}
            </h5>
            <p className={[classes.etfsGridHalfText, "font-20"].join(" ")}>
              {t("investment_6_bot_row32")}
            </p>
          </div>
        </div>
        <div className={[classes.etfsGridHalf, "market-col-6"].join(" ")}>
          <div className={classes.etfsGridHalfWrap}>
            <img src={icon4} className={classes.etfsGridHalfImg} alt=''/>
            <h5 className={[classes.etfsGridHalfTitle, "font-32"].join(" ")}>
              {t("investment_6_bot_row41")}
            </h5>
            <p className={[classes.etfsGridHalfText, "font-20"].join(" ")}>
              {t("investment_6_bot_row42")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ETFs;
