import React from "react";
import classes from "./Markets.module.scss";
import img from "../../../assets/img/InvestmentChoices/banner-img.png";
import { useTranslation } from "react-i18next";

const Markets = () => {
  const { t } = useTranslation();

  return (
    <div className={[classes.marketsContainer, "container"].join(" ")}>
      <div className={classes.marketsRow}>
        <div className={[classes.marketsWrap, "market-col-9"].join(" ")}>
          <img src={img} className={classes.marketsImg} alt=''/>
          <h3 className={[classes.marketsTitle, "font-48 fw-bolder"].join(" ")}>
            {t("investment_2_item1")}
          </h3>
          <p className={[classes.marketsText, "font-24 fw-light"].join(" ")}>
            {t("investment_2_item2")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Markets;
