import React, { useRef, useEffect } from "react";
import classes from "./LowPricing.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const LowPricing = () => {
  const { t } = useTranslation();
  const low_pricing_ref = useRef(null);
  const card_ref_01 = useRef(null);
  const card_ref_02 = useRef(null);
  const card_ref_03 = useRef(null);
  const cards = [
    {
      id: 1,
      ref: card_ref_01,
      title: "$0",
      text: t("main_6_item11"),
    },
    {
      id: 2,
      ref: card_ref_02,
      title: "65 C",
      text: t("main_6_item12"),
    },
    {
      id: 3,
      ref: card_ref_03,
      title: "$1.50",
      text: t("main_6_item13"),
    },
  ];
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      setTimeout(() => {
          gsap.timeline().fromTo(
            card_ref_01.current,
            {
              y: 200,
            },
            {
              y: 0,
              ease: "none",
              scrollTrigger: {
                trigger: low_pricing_ref.current,
                end: "+=200px",
                scrub: 1,
              },
            }
          );
          gsap.timeline().fromTo(
            card_ref_02.current,
            {
              y: 300,
            },
            {
              y: 0,
              ease: "none",
              scrollTrigger: {
                trigger: low_pricing_ref.current,
                end: "+=200px",
                scrub: 1,
              },
            }
          );
          gsap.timeline().fromTo(
            card_ref_03.current,
            {
              y: 400,
            },
            {
              y: 0,
              ease: "none",
              scrollTrigger: {
                trigger: low_pricing_ref.current,
                end: "+=200px",
                scrub: 1,
              },
            }
          );

      }, 500);
    }
  }, []);
  return (
    <section ref={low_pricing_ref} className="container">
      <div className={classes.lowPricing}>
        <h2 className={[classes.title, "font-64"].join(" ")}>
          {t("main_6_title")}
        </h2>
        <div className={classes.cards}>
          {cards.map((card) => (
            <div ref={card.ref} key={card.id} className={classes.card}>
              <h3 className={classes.cardTitle}>{card.title}</h3>
              <p className={classes.cardText}>{card.text}</p>
            </div>
          ))}
        </div>
        <div className={classes.btn}>
          <Button>{t("view_btn")}</Button>
        </div>
      </div>
    </section>
  );
};

export default LowPricing;
