import React from "react";
import classes from "./MarginTrading.module.scss";
import icon_01 from "../../../assets/img/MainPage/MarginTrading/icon_01.svg";
import icon_02 from "../../../assets/img/MainPage/MarginTrading/icon_02.svg";
import icon_03 from "../../../assets/img/MainPage/MarginTrading/icon_03.svg";
import image from "../../../assets/img/MainPage/MarginTrading/image.png";
import { useTranslation } from "react-i18next";

const MarginTrading = () => {
  const { t } = useTranslation();
  const cards = [
    {
      id: 1,
      icon: icon_01,
      title: "main_5_item11",
      text: "main_5_item12",
    },
    {
      id: 2,
      icon: icon_02,
      title: "main_5_item21",
      text: "main_5_item22",
    },
    {
      id: 3,
      icon: icon_03,
      title: "main_5_item31",
      text: "main_5_item32",
    },
  ];

  return (
    <section className={["container", classes.container].join(" ")}>
      <div className={classes.marginTrading}>
        <div className={classes.content}>
          <h2 className={[classes.title, "font-64"].join(" ")}>
            {t("main_5_title")}
          </h2>
          <p className={[classes.text, "font-20"].join(" ")}>
            {t("main_5_subtitle")}
          </p>
        </div>
        <h3 className={classes.subtitle}>{t("main_5_text")}</h3>
        <div className={classes.cards}>
          {cards.map((card) => (
            <div className={classes.card} key={card.id}>
              <img
                className={[classes.cardIcon, "no-select"].join(" ")}
                src={card.icon}
                alt={card.title}
              />
              <h4 className={[classes.cardTitle, "font-32"].join(" ")}>
                {t(card.title)}
              </h4>
              <p className={[classes.cardText, "font-20"].join(" ")}>
                {t(card.text)}
              </p>
            </div>
          ))}
        </div>
        <img
          className={[classes.image, "no-select"].join(" ")}
          src={image}
          alt=""
        />
      </div>
    </section>
  );
};

export default MarginTrading;
