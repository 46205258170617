import React from "react";
import classes from "./Button.module.scss";
import Cookies from 'js-cookie';

const Button = (props) => {
  const lang = Cookies.get('i18next');
  return (
    <a href={`https://client.saberx.net/signup?lang=${lang}`} className={classes.button}>
      <span>{props.children}</span>
    </a>
  );
};

export default Button;
