import React, { useEffect } from "react";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/PricingPage/Banner/Banner";
import DetailedPricing from "../../components/PricingPage/DetailedPricing/DetailedPricing";

const PricingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <DetailedPricing />
      <Footer /> 
    </div>
  );
};

export default PricingPage;
