import React, { useRef, useEffect, useState } from "react";
import classes from "./InvestmentChoicesPage.module.scss";
import Header from "../../components/GlobalComponents/Header/Header"
import Banner from "../../components/InvestmentСhoices/Banner/Banner"
import Markets from "../../components/InvestmentСhoices/Markets/Markets"
import Stocks from "../../components/InvestmentСhoices/Markets/Stocks/Stocks"
import Options from "../../components/InvestmentСhoices/Markets/Options/Options"
import Futures from "../../components/InvestmentСhoices/Markets/Futures/Futures"
import ETFs from "../../components/InvestmentСhoices/Markets/ETFs/ETFs"
import LowPricing from "../../components/MainPage/LowPricing/LowPricing";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const InvestmentChoicesPage = () => {
  const [ currentTab, setCurrentTab ] = useState('stocks'); 
  const myRef = useRef(null);

  useEffect(() => {
    const content = document.getElementById('content-1').offsetHeight;
    const stocks = document.getElementById('markets-stocks').offsetHeight;
    const options = document.getElementById('markets-options').clientHeight;
    const futures = document.getElementById('markets-futures').clientHeight;
    const efts = document.getElementById('markets-efts').clientHeight;
    let progresLine = document.getElementById('progress-bar').style
    progresLine.setProperty('--height', `${stocks}px`);
    gsap.timeline()
    .to(myRef.current, {
      y: content,
      scrollTrigger: {
        trigger: '#markets-stocks',
        start: `top 50px`,
        end: `+=${content+800}px`,
        scrub: 1,
        id: "scrub",
      }
    })
    .to(myRef.current, {
      scrollTrigger: {
        trigger: '#markets-stocks',
        onEnter: () => {
          progresLine.setProperty('--top', `0`);
          progresLine.setProperty('--height', `${stocks}px`);
        },
        onEnterBack: () => {
          progresLine.setProperty('--top', `0`);
          progresLine.setProperty('--height', `${stocks}px`);
        },
      }
    })
    .to(myRef.current, {
      scrollTrigger: {
        trigger: '#markets-options',
        start: `top 150px`,
        end: `bottom`,
        onEnter: () => {
          progresLine.setProperty('--top', `${stocks}px`);
          progresLine.setProperty('--height', `${options}px`);
        },
        onEnterBack: () => {
          progresLine.setProperty('--top', `${stocks}px`);
          progresLine.setProperty('--height', `${options}px`);
        },
      }
    })
    .to(myRef.current, {
      scrollTrigger: {
        trigger: '#markets-futures',
        start: `top 150px`,
        end: `bottom`,
        onEnter: () => {
          progresLine.setProperty('--top', `${stocks + options}px`);
          progresLine.setProperty('--height', `${futures}px`);
        },
        onEnterBack: () => {
          progresLine.setProperty('--top', `${stocks + options}px`);
          progresLine.setProperty('--height', `${futures}px`);
        },
      }
    })
    .to(myRef.current, {
      scrollTrigger: {
        trigger: '#markets-efts',
        start: `top 150px`,
        end: `bottom`,
        onEnter: () => {
          progresLine.setProperty('--top', `${stocks + options + futures}px`);
          progresLine.setProperty('--height', `${efts}px`);
        },
        onEnterBack: () => {
          progresLine.setProperty('--top', `${stocks + options + futures}px`);
          progresLine.setProperty('--height', `${efts}px`);
        },
      }
    })
  }, [])
  return (
    <div className={classes.InvestmentChoicesSection}>
      <Header />
      <Banner />
      <Markets />
      <div className={[classes.stocksContainer, 'container'].join(" ")}>
        <div className={classes.stocksRow} id="content-1">
          <div className={[classes.stocksProgressBar, 'market-col-3'].join(" ")}>
            <div className={classes.stocksProgressBarWrap}>
                <div className={classes.progressBar} id="progress-bar"><span ref={myRef} className={classes.progressIndicator} id="progress-indicator"></span></div>
            </div>
          </div>
          <div className={classes.tabsBtnWrap}>
            <div className={`${classes.tabsBtn} ${'stocks' === currentTab && classes.btnActive}`} onClick={() => setCurrentTab('stocks')}>Stocks</div>
            <div className={`${classes.tabsBtn} ${'options' === currentTab && classes.btnActive}`} onClick={() => setCurrentTab('options')}>Options</div>
            <div className={`${classes.tabsBtn} ${'futures' === currentTab && classes.btnActive}`} onClick={() => setCurrentTab('futures')}>Futures</div>
            <div className={`${classes.tabsBtn} ${'etfs' === currentTab && classes.btnActive}`} onClick={() => setCurrentTab('etfs')}>ETFs</div>
          </div>
          <div className={[classes.stocksDescriptionBlock, 'market-col-9'].join(" ")}>
            <Stocks currentTab={currentTab} />
            <Options currentTab={currentTab} />
            <Futures currentTab={currentTab} />
            <ETFs currentTab={currentTab} />
          </div>
          <div className={classes.tabsBtnWrap}>
            <div className={`${classes.tabsBtn} ${'stocks' === currentTab && classes.btnActive}`} onClick={() => setCurrentTab('stocks')}>Stocks</div>
            <div className={`${classes.tabsBtn} ${'options' === currentTab && classes.btnActive}`} onClick={() => setCurrentTab('options')}>Options</div>
            <div className={`${classes.tabsBtn} ${'futures' === currentTab && classes.btnActive}`} onClick={() => setCurrentTab('futures')}>Futures</div>
            <div className={`${classes.tabsBtn} ${'etfs' === currentTab && classes.btnActive}`} onClick={() => setCurrentTab('etfs')}>ETFs</div>
          </div>
        </div>
      </div>
      <LowPricing />
      <Footer />
    </div>
  );
};

export default InvestmentChoicesPage;
