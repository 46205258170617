import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Banner.module.scss";
import image from "../../../assets/img/PricingPage/banner.png";

import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  const cards = [
    {
      id: 1,
      title: "$0",
      text: t("pricing_1_item11"),
    },
    {
      id: 2,
      title: "$0.65",
      text: t("pricing_1_item21"),
      desc: t("pricing_1_item22"),
    },
    {
      id: 3,
      title: "$1.50",
      text: t("pricing_1_item31"),
    },
  ];
  return (
    <section className="container">
      <div className={classes.banner}>
        <h1 className={[classes.title, "font-80"].join(" ")}>
          {t("pricing_1_title")}
        </h1>
        <h2 className={classes.text}>{t("pricing_1_subtitle")}</h2>
        <div className={classes.btn}>
          <Button> {t("start_btn")}</Button>
        </div>
        <img
          className="no-select"
          src={image}
          alt="Are You ready to Meet the Lowest Pricing on the Market?"
        />
      </div>
      <div className={classes.cards}>
        {cards.map((card) => (
          <div key={card.id} className={classes.card}>
            <div className={classes.cardAlign}>
              <h3 className={classes.cardTitle}>{t(card.title)}</h3>
              <p className={classes.cardText}>{t(card.text)}</p>
              {card.desc && <p className={classes.cardDesc}>{t(card.desc)}</p>}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Banner;
