import React from "react";
import classes from "./Sharpen.module.scss";
import icon_01 from "../../../assets/img/MainPage/Sharpen/icon_01.png";
import icon_02 from "../../../assets/img/MainPage/Sharpen/icon_02.png";
import icon_03 from "../../../assets/img/MainPage/Sharpen/icon_03.png";
import icon_04 from "../../../assets/img/MainPage/Sharpen/icon_04.png";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const Sharpen = () => {
  const { t } = useTranslation();

  const cards = [
    {
      id: 1,
      icon: icon_01,
      title: "main_3_item11",
      text: "main_3_item12",
    },
    {
      id: 2,
      icon: icon_02,
      title: "main_3_item21",
      text: "main_3_item22",
    },
    {
      id: 3,
      icon: icon_03,
      title: "main_3_item31",
      text: "main_3_item32",
    },
    {
      id: 4,
      icon: icon_04,
      title: "main_3_item41",
      text: "main_3_item42",
    },
  ];
  return (
    <section className="container">
      <div className={classes.sharpen}>
        <h2 className={[classes.title, "font-64"].join(" ")}>
          {t("main_3_title")}
        </h2>
        <div className={classes.cards}>
          {cards.map((card) => (
            <div key={card.id} className={classes.card}>
              <img className="no-select" src={card.icon} alt={card.title} />
              <h3 className={[classes.cardTitle, "font-32"].join(" ")}>
                <Trans>{t(card.title)}</Trans>
              </h3>
              <p className={[classes.cardText, "font-20"].join(" ")}>
                <Trans>{t(card.text)}</Trans>
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Sharpen;
