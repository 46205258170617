import React from "react";
import classes from "./Banner.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import laptop from "../../../assets/img/MainPage/Banner/laptop.png";
import graph from "../../../assets/img/MainPage/Banner/graph.png";
import sphere from "../../../assets/img/MainPage/Banner/sphere.png";
import money_01_left from "../../../assets/img/MainPage/Banner/money_01_left.png";
import money_02_left from "../../../assets/img/MainPage/Banner/money_02_left.png";
import money_03_left from "../../../assets/img/MainPage/Banner/money_03_left.png";
import money_04_right from "../../../assets/img/MainPage/Banner/money_04_right.png";
import money_05_right from "../../../assets/img/MainPage/Banner/money_05_right.png";
import money_06_right from "../../../assets/img/MainPage/Banner/money_06_right.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
  const { t } = useTranslation();
  const contentRef = useRef(null);
  const moneyRef01 = useRef(null);
  const moneyRef02 = useRef(null);
  const moneyRef03 = useRef(null);
  const moneyRef04 = useRef(null);
  const moneyRef05 = useRef(null);
  const moneyRef06 = useRef(null);
  const moneyRefWr01 = useRef(null);
  const moneyRefWr02 = useRef(null);
  const moneyRefWr03 = useRef(null);
  const moneyRefWr04 = useRef(null);
  const moneyRefWr05 = useRef(null);
  const moneyRefWr06 = useRef(null);
  const moneyRef = useRef(null);
  const titleRef = useRef(null);
  const imagesRef = useRef(null);
  const graphRef = useRef(null);
  const graphWrRef = useRef(null);
  const sphereRef = useRef(null);
  const sphereWrRef = useRef(null);
  useEffect(() => {
    let toScale = 1;
    if (document.documentElement.clientWidth < 1650) {
      toScale = 0.8;
    }
    if (document.documentElement.clientWidth > 1220) {
      gsap.to(contentRef.current, {
        scale: 1,
        y: 0,
        opacity: 1,
        delay: 0.5,
        duration: 1,
      });
      gsap.to(imagesRef.current, {
        scale: 1,
        y: 0,
        opacity: 1,
        delay: 0.5,
        duration: 1,
      });

      gsap.to(moneyRef01.current, {
        scale: toScale,
        y: 0,
        x: 0,
        opacity: 1,
        delay: 1.5,
        duration: 1,
      });
      gsap.to(moneyRef02.current, {
        scale: toScale,
        y: 0,
        x: 0,
        opacity: 1,
        delay: 1.5,
        duration: 1,
      });
      gsap.to(moneyRef03.current, {
        scale: toScale,
        y: 0,
        x: 0,
        opacity: 1,
        delay: 1.5,
        duration: 1,
      });
      gsap.to(moneyRef04.current, {
        scale: toScale,
        y: 0,
        x: 0,
        opacity: 1,
        delay: 1.5,
        duration: 1,
      });
      gsap.to(moneyRef05.current, {
        scale: toScale,
        y: 0,
        x: 0,
        opacity: 1,
        delay: 1.5,
        duration: 1,
      });
      gsap.to(moneyRef06.current, {
        scale: toScale,
        y: 0,
        x: 0,
        opacity: 1,
        delay: 1.5,
        duration: 1,
      });

      gsap.to(graphRef.current, {
        scale: 1,
        y: 0,
        x: 0,
        opacity: 1,
        delay: 1.5,
        duration: 1,
      });
      gsap.to(sphereRef.current, {
        scale: 1,
        y: 0,
        x: 0,
        opacity: 1,
        delay: 1.5,
        duration: 1,
      });

      gsap.timeline().to(moneyRef.current, {
        y: 300,
        opacity: 0,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "bottom top",
          end: "+=300px",
          scrub: 2,
        },
      });
      gsap.timeline().to(moneyRefWr01.current, {
        x: -100,
        y: 100,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "bottom top",
          end: "+=300px",
          scrub: 2,
        },
      });
      gsap.timeline().to(moneyRefWr02.current, {
        x: -150,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "bottom top",
          end: "+=300px",
          scrub: 2,
        },
      });
      gsap.timeline().to(moneyRefWr04.current, {
        x: 20,
        y: 200,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "bottom top",
          end: "+=300px",
          scrub: 2,
        },
      });
      gsap.timeline().to(moneyRefWr05.current, {
        y: 100,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "bottom top",
          end: "+=300px",
          scrub: 2,
        },
      });
      gsap.timeline().to(moneyRefWr06.current, {
        x: 150,
        y: 50,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "bottom top",
          end: "+=300px",
          scrub: 2,
        },
      });

      gsap.timeline().to(graphWrRef.current, {
        x: -300,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "bottom top",
          end: "+=300px",
          scrub: 3,
        },
      });
      gsap.timeline().to(sphereWrRef.current, {
        x: 300,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "bottom top",
          end: "+=300px",
          scrub: 3,
        },
      });
    }
  }, []);
  return (
    <section className={classes.banner}>
      <div className="container">
        <div ref={contentRef} className={classes.content}>
          <h1 ref={titleRef} className={[classes.title, "font-80"].join(" ")}>
            {t("main_1_title")}
          </h1>
          <h2 className={[classes.subtitle, "font-32"].join(" ")}>
            {t("main_1_subtitle")}
          </h2>
          <p className={[classes.text, "font-20"].join(" ")}>
            {t("main_1_text")}
          </p>
          <div className={classes.btn}>
            <Button> {t("open_btn")}</Button>
          </div>
        </div>
      </div>
      <div ref={imagesRef} className={[classes.images, "no-select"].join(" ")}>
        <img className={classes.laptop} src={laptop} alt="" />
        <div ref={moneyRef} className={classes.money}>
          <div ref={moneyRefWr01}>
            <img
              ref={moneyRef01}
              className={classes.money_01_left}
              src={money_01_left}
              alt=""
            />
          </div>
          <div ref={moneyRefWr02}>
            <img
              ref={moneyRef02}
              className={classes.money_02_left}
              src={money_02_left}
              alt=""
            />
          </div>
          <div ref={moneyRefWr03}>
            <img
              ref={moneyRef03}
              className={classes.money_03_left}
              src={money_03_left}
              alt=""
            />
          </div>
          <div ref={moneyRefWr04}>
            <img
              ref={moneyRef04}
              className={classes.money_04_right}
              src={money_04_right}
              alt=""
            />
          </div>
          <div ref={moneyRefWr05}>
            <img
              ref={moneyRef05}
              className={classes.money_05_right}
              src={money_05_right}
              alt=""
            />
          </div>
          <div ref={moneyRefWr06}>
            <img
              ref={moneyRef06}
              className={classes.money_06_right}
              src={money_06_right}
              alt=""
            />
          </div>
        </div>
        <div ref={graphWrRef}>
          <img ref={graphRef} className={classes.graph} src={graph} alt="" />
        </div>
        <div ref={sphereWrRef}>
          <img ref={sphereRef} className={classes.sphere} src={sphere} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Banner;
