import React, { useState } from "react";
import classes from "./Row.module.scss";
import img1 from "../../../assets/img/QuickStart/1/1.png";
import img2 from "../../../assets/img/QuickStart/1/2.png";
import img3 from "../../../assets/img/QuickStart/1/3.png";
import Button from "../../GlobalComponents/Button/Button";

import { useTranslation } from "react-i18next";
import "./Row.scss";

const Row = () => {
  const { t } = useTranslation();
  const [columnNumber, setColumnNumber] = useState(0);

  return (
    <div className={classes.row}>
      <div className={classes.rowBody}>
        <div className="container">
          <div className={classes.rowColumsWrapper}>
            <div className={classes.rowColums}>
              <div
                className={
                  columnNumber === 1
                    ? [
                        classes.rowColumsColumn,
                        `rowColumsColumn_${t("lang")}`,
                        classes.rowColumsColumn1,
                        classes.active,
                      ].join(" ")
                    : [
                        classes.rowColumsColumn1,
                        classes.rowColumsColumn,
                        `rowColumsColumn_${t("lang")}`,
                      ].join(" ")
                }
              >
                <div className={classes.rowColumsColumnItem}>
                  <div className={classes.rowColumsColumnItemImg}>
                    <img src={img1} alt="" />
                  </div>
                  <div
                    className={[
                      classes.rowColumsColumnItemTitle,
                      `font-40 rowColumsColumnItemTitle_${t("lang")}`,
                    ].join(" ")}
                  >
                    {t("qucikstart_2_item111")}
                  </div>
                  <div
                    className={[
                      classes.rowColumsColumnItemSubtitle,
                      `font-20 rowColumsColumnItemSubtitle_${t("lang")}`,
                    ].join(" ")}
                  >
                    {t("qucikstart_2_item112")}
                  </div>
                </div>
                <div className={classes.rowColumsColumnSecondItem}>
                  <div
                    className={[
                      classes.rowColumsColumnSecondItemTitle,
                      `font-24 rowColumsColumnSecondItemTitle_${t("lang")}`,
                    ].join(" ")}
                  >
                    {t("qucikstart_2_item121")}
                  </div>
                  <div className={classes.rowColumsColumnSecondItemSubList}>
                    <div
                      className={[
                        classes.rowColumsColumnSecondItemSubListItem,
                        `rowColumsColumnSecondItemSubListItem_${t("lang")}`,
                      ].join(" ")}
                    >
                      {t("qucikstart_2_item122")}
                    </div>
                  </div>
                  <div
                    className={[
                      classes.rowColumsColumnSecondItemTitle,
                      `font-24 rowColumsColumnSecondItemTitle_${t("lang")}`,
                    ].join(" ")}
                  >
                    {t("qucikstart_2_item123")}
                  </div>
                  <div className={classes.rowColumsColumnSecondItemSubList}>
                    <div
                      className={classes.rowColumsColumnSecondItemSubListItem}
                    >
                      {t("qucikstart_2_item124")}
                    </div>
                    <div
                      className={classes.rowColumsColumnSecondItemSubListItem}
                    >
                      {t("qucikstart_2_item125")}
                    </div>
                    <div
                      className={classes.rowColumsColumnSecondItemSubListItem}
                    >
                      {t("qucikstart_2_item126")}
                    </div>
                  </div>
                  <div className={classes.rowColumsColumnSecondItemText}>
                    {t("qucikstart_2_item127")}
                  </div>
                </div>
                <div
                  className={classes.rowColumsColumnArrow}
                  onClick={() => {
                    if (columnNumber === 1) {
                      setColumnNumber(0);
                    } else {
                      setColumnNumber(1);
                    }
                  }}
                ></div>
                <div className={classes.rowColumsColumnOpentBtn}>
                  <Button> {t("open_btn")}</Button>
                </div>
              </div>
              <div
                className={
                  columnNumber === 2
                    ? [
                        classes.rowColumsColumn,
                        `rowColumsColumn_${t("lang")}`,
                        classes.rowColumsColumn2,
                        classes.active,
                      ].join(" ")
                    : [
                        classes.rowColumsColumn2,
                        classes.rowColumsColumn,
                        `rowColumsColumn_${t("lang")}`,
                      ].join(" ")
                }
              >
                <div className={classes.rowColumsColumnItem}>
                  <div className={classes.rowColumsColumnItemImg}>
                    <img src={img2} alt="" />
                  </div>
                  <div
                    className={[
                      classes.rowColumsColumnItemTitle,
                      `font-40 rowColumsColumnItemTitle_${t("lang")}`,
                    ].join(" ")}
                  >
                    {t("qucikstart_2_item211")}
                  </div>
                  <div
                    className={[
                      classes.rowColumsColumnItemSubtitle,
                      `font-20 rowColumsColumnItemSubtitle_${t("lang")}`,
                    ].join(" ")}
                  >
                    {t("qucikstart_2_item212")}
                  </div>
                </div>
                <div className={classes.rowColumsColumnSecondItem}>
                  <div
                    className={[
                      classes.rowColumsColumnSecondItemTitle,
                      `font-24 rowColumsColumnSecondItemTitle_${t("lang")}`,
                    ].join(" ")}
                  >
                    {t("qucikstart_2_item221")}
                  </div>
                  <div className={classes.rowColumsColumnSecondItemSubList}>
                    <div
                      className={classes.rowColumsColumnSecondItemSubListItem}
                    >
                      {t("qucikstart_2_item222")}
                    </div>
                    <div
                      className={classes.rowColumsColumnSecondItemSubListItem}
                    >
                      {t("qucikstart_2_item223")}
                    </div>
                    <div
                      className={classes.rowColumsColumnSecondItemSubListItem}
                    >
                      {t("qucikstart_2_item224")}
                    </div>
                  </div>
                  <div
                    className={[
                      classes.rowColumsColumnSecondItemTitle,
                      `font-24 rowColumsColumnSecondItemTitle_${t("lang")}`,
                    ].join(" ")}
                  >
                    {t("qucikstart_2_item225")}
                  </div>
                  <div className={classes.rowColumsColumnSecondItemSubList}>
                    <div
                      className={classes.rowColumsColumnSecondItemSubListItem}
                    >
                      {t("qucikstart_2_item226")}
                    </div>
                    <div
                      className={classes.rowColumsColumnSecondItemSubListItem}
                    >
                      {t("qucikstart_2_item227")}
                    </div>
                  </div>
                  <div className={classes.rowColumsColumnSecondItemText}>
                    {t("qucikstart_2_item228")}
                  </div>
                </div>
                <div
                  className={classes.rowColumsColumnArrow}
                  onClick={() => {
                    if (columnNumber === 2) {
                      setColumnNumber(0);
                    } else {
                      setColumnNumber(2);
                    }
                  }}
                ></div>
                <div className={classes.rowColumsColumnOpentBtn}>
                  <Button> {t("open_btn")}</Button>
                </div>
              </div>
              <div
                className={
                  columnNumber === 3
                    ? [
                        classes.rowColumsColumn,
                        `rowColumsColumn_${t("lang")}`,
                        classes.rowColumsColumn3,
                        classes.active,
                      ].join(" ")
                    : [
                        classes.rowColumsColumn3,
                        classes.rowColumsColumn,
                        `rowColumsColumn_${t("lang")}`,
                      ].join(" ")
                }
              >
                <div className={classes.rowColumsColumnItem}>
                  <div className={classes.rowColumsColumnItemImg}>
                    <img src={img3} alt="" />
                  </div>
                  <div
                    className={[
                      classes.rowColumsColumnItemTitle,
                      `font-40 rowColumsColumnItemTitle_${t("lang")}`,
                    ].join(" ")}
                  >
                    {t("qucikstart_3_item111")}
                  </div>
                  <div
                    className={[
                      classes.rowColumsColumnItemSubtitle,
                      `font-20 rowColumsColumnItemSubtitle_${t("lang")}`,
                    ].join(" ")}
                  >
                    {t("qucikstart_3_item112")}
                    <span> {t("qucikstart_3_item113")}</span>
                  </div>
                </div>
                <div className={classes.rowColumsColumnSecondItem}>
                  <div
                    className={[
                      classes.rowColumsColumnSecondItemTitle,
                      `font-24 rowColumsColumnSecondItemTitle_${t("lang")}`,
                    ].join(" ")}
                  >
                    {t("qucikstart_3_item121")}
                  </div>
                  <div className={classes.rowColumsColumnSecondItemSubList}>
                    <div
                      className={classes.rowColumsColumnSecondItemSubListItem}
                    >
                      {t("qucikstart_3_item122")}
                    </div>
                    <div
                      className={classes.rowColumsColumnSecondItemSubListItem}
                    >
                      {t("qucikstart_3_item123")}
                    </div>
                  </div>
                </div>
                <div
                  className={classes.rowColumsColumnArrow}
                  onClick={() => {
                    if (columnNumber === 3) {
                      setColumnNumber(0);
                    } else {
                      setColumnNumber(3);
                    }
                  }}
                ></div>
                <div className={classes.rowColumsColumnOpentBtn}>
                  <Button> {t("open_btn")}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
