import React, { useEffect } from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Need from "../../components/QuickStartComponents/Need/Need";
import Row from "../../components/QuickStartComponents/Row/Row";
import Benefits from "../../components/QuickStartComponents/Benefits/Benefits";
import How from "../../components/QuickStartComponents/How/How";
import MarginTrading from "../../components/MainPage/MarginTrading/MarginTrading";

const QuickStartPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <Need />
      <Row />
      <MarginTrading />
      <Benefits />
      <How />
      <Footer />
    </div>
  );
};

export default QuickStartPage;
