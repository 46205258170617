import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Need.module.scss";

import { useTranslation } from "react-i18next";

const Need = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.need}>
      <div className={classes.needBody}>
        <div className="container">
          <div className={[classes.overTitle, "font-48"].join(" ")}>
            {t("qucikstart_1_title")}
          </div>
          <div className={[classes.needTitle, "font-80"].join(" ")}>
            {t("qucikstart_1_subtitle")}
          </div>
          <div className={[classes.needSubtitle, "font-32"].join(" ")}>
            {t("qucikstart_1_text")}
          </div>
          <div className={classes.needStartBtn}>
            <Button>{t("start_btn")}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Need;
