import React from "react";
import classes from "./Options.module.scss";
import icon1 from "../../../../assets/img/InvestmentChoices/markets/options-icon-1.png";
import icon2 from "../../../../assets/img/InvestmentChoices/markets/options-icon-2.png";
import icon3 from "../../../../assets/img/InvestmentChoices/markets/options-icon-3.png";
import icon4 from "../../../../assets/img/InvestmentChoices/markets/options-icon-4.png";
import { useTranslation } from "react-i18next";

const Options = ({ currentTab }) => {
  const { t } = useTranslation();
  return (
    <section
      className={`${classes.optionsWrap} ${"options" === currentTab && classes.tabActive}`}
      id="markets-options"
    >
      <h3 className="stocksProgressBarTitle">{t("investment_4_item11")}</h3>
      <p className={[classes.optionsText, "font-24 fw-light"].join(" ")}>
        {t("investment_4_item12")}
      </p>
      <div className={classes.optionsGridRow}>
        <div className={classes.optionsGridBigItem}>
          <h5 className={[classes.optionsGridSmallItemTitle, "font-32"].join(" ")}>
            50¢
          </h5>
          <p className={[classes.optionsGridSmallItemSubTitle, "font-24"].join(" ")}>
            {t("investment_4_row11")}
          </p>
          <p className={[classes.optionsGridSmallItemText, "font-20"].join(" ")}>
            {t("investment_4_row12")}
          </p>
        </div>
        <div className={classes.optionsGridSmallItem}>
          <h5 className={[classes.optionsGridSmallItemTitle, "font-32"].join(" ")}>
            $1.50
          </h5>
          <p className={[classes.optionsGridSmallItemSubTitle, "font-24"].join(" ")}>
            {t("investment_4_row21")}
          </p>
          <p className={[classes.optionsGridSmallItemText, "font-20"].join(" ")}>
            {t("investment_4_row22")}
          </p>
        </div>
      </div>
      <h3 className={[classes.optionsSubTitle, "font-36"].join(" ")}>
        {t("investment_4_bot_title")}
      </h3>
      <div className={classes.optionsGridRow}>
        <div className={[classes.optionsGridHalf, "market-col-6"].join(" ")}>
          <div
            className={[
              classes.optionsGridHalfWrap,
              classes.borderBottom,
              classes.borderRight,
            ].join(" ")}
          >
            <img src={icon1} className={classes.optionsGridHalfImg} alt=''/>
            <h5 className={[classes.optionsGridHalfTitle, "font-32"].join(" ")}>
              {t("investment_4_bot_row11")}
            </h5>
            <p className={[classes.optionsGridHalfText, "font-20"].join(" ")}>
              {t("investment_4_bot_row12")}
            </p>
          </div>
        </div>
        <div className={[classes.optionsGridHalf, "market-col-6"].join(" ")}>
          <div className={[classes.optionsGridHalfWrap, classes.borderBottom].join(" ")}>
            <img src={icon2} className={classes.optionsGridHalfImg} alt=''/>
            <h5 className={[classes.optionsGridHalfTitle, "font-32"].join(" ")}>
              {t("investment_4_bot_row21")}
            </h5>
            <p className={[classes.optionsGridHalfText, "font-20"].join(" ")}>
              {t("investment_4_bot_row22")}
            </p>
          </div>
        </div>
        <div className={[classes.optionsGridHalf, "market-col-6"].join(" ")}>
          <div className={[classes.optionsGridHalfWrap, classes.borderRight].join(" ")}>
            <img src={icon3} className={classes.optionsGridHalfImg} alt=''/>
            <h5 className={[classes.optionsGridHalfTitle, "font-32"].join(" ")}>
              {t("investment_4_bot_row31")}
            </h5>
            <p className={[classes.optionsGridHalfText, "font-20"].join(" ")}>
              {t("investment_4_bot_row32")}
            </p>
          </div>
        </div>
        <div className={[classes.optionsGridHalf, "market-col-6"].join(" ")}>
          <div className={classes.optionsGridHalfWrap}>
            <img src={icon4} className={classes.optionsGridHalfImg} alt=''/>
            <h5 className={[classes.optionsGridHalfTitle, "font-32"].join(" ")}>
              {t("investment_4_bot_row41")}
            </h5>
            <p className={[classes.optionsGridHalfText, "font-20"].join(" ")}>
              {t("investment_4_bot_row42")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Options;
