import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./How.module.scss";

import { useTranslation } from "react-i18next";

const How = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.how}>
      <div className={classes.howBody}>
        <div className="container">
          <div className={[classes.howTitle, "font-64"].join(" ")}>
            {t("qucikstart_5_item1")}
          </div>
          <div className={classes.howRow}>
            <div className={classes.howRowColumn}>
              <div className={[classes.howRowColumnText, "font-24"].join(" ")}>
                {t("qucikstart_5_item2")}
              </div>
            </div>
            <div className={classes.howRowColumn}>
              <div className={[classes.howRowColumnText, "font-24"].join(" ")}>
                {t("qucikstart_5_item3")}
              </div>
            </div>
            <div className={classes.howRowColumn}>
              <div className={[classes.howRowColumnText, "font-24"].join(" ")}>
                {t("qucikstart_5_item4")}
              </div>
            </div>
            <div className={classes.howRowColumn}>
              <div className={[classes.howRowColumnText, "font-24"].join(" ")}>
                {t("qucikstart_5_item5")}
              </div>
            </div>
          </div>
          <div className={classes.howOpenBtn}>
            <Button> {t("open_btn")}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default How;
