import React, { useEffect } from "react";
import Banner from "../../components/MainPage/Banner/Banner";
import classes from "./MainPage.module.scss";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import TheValue from "../../components/MainPage/TheValue/TheValue";
import Sharpen from "../../components/MainPage/Sharpen/Sharpen";
import OurInstruments from "../../components/MainPage/OurInstruments/OurInstruments";
import LowPricing from "../../components/MainPage/LowPricing/LowPricing";
import MarginTrading from "../../components/MainPage/MarginTrading/MarginTrading";

const MainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={["ovf-hidden", classes.main].join(" ")}>
      <Header />
      <Banner />
      <TheValue />
      <Sharpen />
      <OurInstruments />
      <MarginTrading />
      <LowPricing />
      <Footer />
    </div>
  );
};

export default MainPage;
