import React, { useEffect } from "react";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import MostPopular from "../../components/FaqPage/MostPopular/MostPopular";

const FaqPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="ovf-hidden">
      <Header />
      <MostPopular />
      <Footer />
    </div>
  );
};

export default FaqPage;
