import React from "react";
import classes from "./TheValue.module.scss";
import image_01 from "../../../assets/img/MainPage/TheValue/image_01.png";
import image_02 from "../../../assets/img/MainPage/TheValue/image_02.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const TheValue = () => {
  const { t } = useTranslation();

  const img_01_ref = useRef(null);
  const img_01_wr_ref = useRef(null);
  const img_02_ref = useRef(null);
  const img_02_wr_ref = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      setTimeout(() => {
        gsap.timeline().to(img_01_ref.current, {
          scale: 1.1,
          ease: "none",
          scrollTrigger: {
            trigger: img_01_ref.current,
            start: "top top",
            end: "bottom top",
            scrub: true,
          },
        });
        gsap.timeline().to(img_02_ref.current, {
          scale: 1.1,
          ease: "none",
          scrollTrigger: {
            trigger: img_02_ref.current,
            start: "top top",
            end: "bottom top",
            scrub: true,
          },
        });
        gsap.timeline().fromTo(
          img_01_wr_ref.current,
          {
            scale: 1.1,
          },
          {
            scale: 1,
            ease: "none",
            scrollTrigger: {
              trigger: img_01_wr_ref.current,
              end: "bottom bottom",
              scrub: true,
            },
          }
        );
        gsap.timeline().fromTo(
          img_02_wr_ref.current,
          {
            scale: 1.1,
          },
          {
            scale: 1,
            ease: "none",
            scrollTrigger: {
              trigger: img_02_wr_ref.current,
              end: "bottom bottom",
              scrub: true,
            },
          }
        );
      }, 500);
    }
  }, []);
  return (
    <section className="container">
      <div className={[classes.theValue, "font-20"].join(" ")}>
        <div className={[classes.row, classes.row_01].join(" ")}>
          <div className={[classes.col, classes.col_text].join(" ")}>
            <h2 className={[classes.title, "font-64"].join(" ")}>
              {t("main_2_title")}
            </h2>
            <p className={classes.text}>{t("main_2_text1")}</p>
          </div>
          <div className={[classes.col, classes.col_img].join(" ")}>
            <div ref={img_01_wr_ref}>
              <img
                ref={img_01_ref}
                className="no-select"
                src={image_01}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className={[classes.row, classes.row_02].join(" ")}>
          <div className={[classes.col, classes.col_img].join(" ")}>
            <div ref={img_02_wr_ref}>
              <img
                ref={img_02_ref}
                className="no-select"
                src={image_02}
                alt=""
              />
            </div>
          </div>
          <div className={[classes.col, classes.col_text].join(" ")}>
            <p>{t("main_2_text2")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TheValue;
