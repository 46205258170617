
import AboutPage from "../pages/AboutPage/AboutPage";
import FaqPage from "../pages/FaqPage/FaqPage";
import InvestmentChoicesPage from "../pages/InvestmentChoicesPage/InvestmentChoicesPage";
import MainPage from "../pages/MainPage/MainPage";
import PricingPage from "../pages/PricingPage/PricingPage";
import QuickStartPage from "../pages/QuickStartPage/QuickStartPage";

export const routes = [
    { path: '/about', element: AboutPage },
    { path: '/faq', element: FaqPage },
    { path: '/investment-choices', element: InvestmentChoicesPage },
    { path: '*', element: MainPage },
    { path: '/pricing-rates', element: PricingPage },
    { path: '/quick-start', element: QuickStartPage },

];

export const headerRoutes = [
    { id: 1, path: '/quick-start', text: "header_item_1" },
    { id: 2, path: '/investment-choices', text: "header_item_2" },
    { id: 3, path: '/pricing-rates', text: "header_item_3" },
    { id: 4, path: '/faq', text: "header_item_4" },
    { id: 5, path: '/about', text: "header_item_5" },
]