import React from "react";
import classes from "./TheValue.module.scss";
import image_01 from "../../../assets/img/AboutPage/1.png";
import image_02 from "../../../assets/img/AboutPage/2.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";
import { useRef } from "react";
import Button from "../../GlobalComponents/Button/Button";

import { useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const TheValue = () => {
  const img_01_ref = useRef(null);
  const img_01_wr_ref = useRef(null);
  const img_02_ref = useRef(null);
  const img_02_wr_ref = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      setTimeout(() => {
        gsap.timeline().to(img_01_ref.current, {
          scale: 1.1,
          ease: "none",
          scrollTrigger: {
            trigger: img_01_ref.current,
            start: "top top",
            end: "bottom top",
            scrub: true,
          },
        });
        gsap.timeline().to(img_02_ref.current, {
          scale: 1.1,
          ease: "none",
          scrollTrigger: {
            trigger: img_02_ref.current,
            start: "top top",
            end: "bottom top",
            scrub: true,
          },
        });
        // gsap.timeline().fromTo(
        //   img_01_wr_ref.current,
        //   {
        //     scale: 1.1,
        //   },
        //   {
        //     scale: 1,
        //     ease: "none",
        //     scrollTrigger: {
        //       trigger: img_01_wr_ref.current,
        //       end: "bottom bottom",
        //       scrub: true,
        //     },
        //   }
        // );
        // gsap.timeline().fromTo(
        //   img_02_wr_ref.current,
        //   {
        //     scale: 1.1,
        //   },
        //   {
        //     scale: 1,
        //     ease: "none",
        //     scrollTrigger: {
        //       trigger: img_02_wr_ref.current,
        //       end: "bottom bottom",
        //       scrub: true,
        //     },
        //   }
        // );
      }, 500)
    }
  }, []);
  return (
    <section className="container">
      <div className={[classes.theValue, "font-20"].join(" ")}>
        <div className={classes.contTitle}>
          <h1 className={[classes.titleMain, "font-80"].join(" ")}>
            {t("about_title")}
          </h1>
        </div>
        <div className={[classes.row, classes.row_01].join(" ")}>
          <div className={[classes.col, classes.col_text].join(" ")}>
            <h2 className={[classes.subtitle, "font-32"].join(" ")}>
              {t("about_item11")}
            </h2>
            <p className={classes.text}>{t("about_item12")}</p>
          </div>
          <div className={[classes.col, classes.col_img].join(" ")}>
            <div ref={img_01_wr_ref}>
              <img
                ref={img_01_ref}
                className="no-select"
                src={image_01}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className={[classes.row, classes.row_02].join(" ")}>
          <div className={[classes.col, classes.col_img].join(" ")}>
            <div ref={img_02_wr_ref}>
              <img
                ref={img_02_ref}
                className="no-select"
                src={image_02}
                alt=""
              />
            </div>
          </div>
          <div className={[classes.col, classes.col_text].join(" ")}>
            <p className={classes.mb}>{t("about_item13")}</p>
            <p>{t("about_item14")}</p>
          </div>
        </div>
        <div className={classes.contTitle}>
          <p className={[classes.aboutTextB, "font-32"].join(" ")}>
            {t("about_item15")}
          </p>
          <div className={classes.aboutBtns}>
            <div className={classes.aboutBtnsStart}>
              <Button>{t("start_btn")}</Button>
            </div>
            <div className={classes.aboutBtnsOpen}>
              <span>{t("open_btn")}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TheValue;
