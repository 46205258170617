import React from "react";
import classes from "./Stocks.module.scss";
import icon1 from "../../../../assets/img/InvestmentChoices/markets/trade-stocks-icon-1.png";
import icon2 from "../../../../assets/img/InvestmentChoices/markets/trade-stocks-icon-2.png";
import icon3 from "../../../../assets/img/InvestmentChoices/markets/trade-stocks-icon-3.png";
import icon4 from "../../../../assets/img/InvestmentChoices/markets/trade-stocks-icon-4.png";
import { useTranslation } from "react-i18next";

const Stocks = ({ currentTab }) => {
  const { t } = useTranslation();
  return (
    <section
      className={`${classes.stocksWrap} ${"stocks" === currentTab ? classes.tabActive : currentTab}`}
      id="markets-stocks"
    >
      <h3 className="stocksProgressBarTitle">{t("investment_3_item11")}</h3>
      <p className={[classes.stocksText, "font-24 fw-light"].join(" ")}>
        {t("investment_3_item12")}
      </p>
      <div className={classes.stocksGridRow}>
        <div className={classes.stocksGridSmallItem}>
          <h5
            className={[classes.stocksGridSmallItemTitle, "font-32"].join(" ")}
          >
            {t("investment_3_row11")}
          </h5>
          <p className={[classes.stocksGridSmallItemText, "font-20"].join(" ")}>
            {t("investment_3_row12")}
          </p>
        </div>
        <div className={classes.stocksGridSmallItem}>
          <h5
            className={[classes.stocksGridSmallItemTitle, "font-32"].join(" ")}
          >
            {t("investment_3_row21")}
          </h5>
          <p className={[classes.stocksGridSmallItemText, "font-20"].join(" ")}>
            {t("investment_3_row22")}
          </p>
        </div>
        <div className={classes.stocksGridBigItem}>
          <h5 className={[classes.stocksGridBigItemTitle, "font-32"].join(" ")}>
            {t("investment_3_row31")}
          </h5>
          <ul className={classes.stocksGridBigList}>
            <li className={classes.stocksGridBigListItem}>
              {t("investment_3_row32")}
            </li>
            <li className={classes.stocksGridBigListItem}>
              {t("investment_3_row33")}
            </li>
            <li className={classes.stocksGridBigListItem}>
              {t("investment_3_row34")}
            </li>
          </ul>
        </div>
      </div>
      <h3 className={[classes.stocksSubTitle, "font-36"].join(" ")}>
        {t("investment_3_bot_title")}
      </h3>
      <div className={classes.stocksGridRow}>
        <div className={[classes.stocksGridHalf, "market-col-6"].join(" ")}>
          <div
            className={[
              classes.stocksGridHalfWrap,
              classes.borderBottom,
              classes.borderRight,
            ].join(" ")}
          >
            <img src={icon1} className={classes.stocksGridHalfImg} alt=''/>
            <h5 className={[classes.stocksGridHalfTitle, "font-32"].join(" ")}>
              {t("investment_3_bot_row11")}
            </h5>
            <p className={[classes.stocksGridHalfText, "font-20"].join(" ")}>
              {t("investment_3_bot_row12")}
            </p>
          </div>
        </div>
        <div className={[classes.stocksGridHalf, "market-col-6"].join(" ")}>
          <div
            className={[classes.stocksGridHalfWrap, classes.borderBottom].join(
              " "
            )}
          >
            <img src={icon2} className={classes.stocksGridHalfImg} alt=''/>
            <h5 className={[classes.stocksGridHalfTitle, "font-32"].join(" ")}>
              {t("investment_3_bot_row21")}
            </h5>
            <p className={[classes.stocksGridHalfText, "font-20"].join(" ")}>
              {t("investment_3_bot_row22")}
            </p>
          </div>
        </div>
        <div className={[classes.stocksGridHalf, "market-col-6"].join(" ")}>
          <div
            className={[classes.stocksGridHalfWrap, classes.borderRight].join(
              " "
            )}
          >
            <img src={icon3} className={classes.stocksGridHalfImg} alt=''/>
            <h5 className={[classes.stocksGridHalfTitle, "font-32"].join(" ")}>
              {t("investment_3_bot_row31")}
            </h5>
            <p className={[classes.stocksGridHalfText, "font-20"].join(" ")}>
              {t("investment_3_bot_row32")}
            </p>
          </div>
        </div>
        <div className={[classes.stocksGridHalf, "market-col-6"].join(" ")}>
          <div className={classes.stocksGridHalfWrap}>
            <img src={icon4} className={classes.stocksGridHalfImg} alt=''/>
            <h5 className={[classes.stocksGridHalfTitle, "font-32"].join(" ")}>
              {t("investment_3_bot_row41")}
            </h5>
            <p className={[classes.stocksGridHalfText, "font-20"].join(" ")}>
              {t("investment_3_bot_row42")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stocks;
