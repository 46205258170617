import React, { useRef, useEffect } from "react";
import classes from "./MostPopular.module.scss";
import $ from "jquery";
import { useTranslation } from "react-i18next";

const MostPopular = () => {
  const { t } = useTranslation();
  const tab01Ref = useRef(null);
  const tab02Ref = useRef(null);
  const tab03Ref = useRef(null);
  const tab04Ref = useRef(null);
  const tab05Ref = useRef(null);
  useEffect(() => {
    $(tab01Ref.current).slideUp(0);
    $(tab02Ref.current).slideUp(0);
    $(tab03Ref.current).slideUp(0);
    $(tab04Ref.current).slideUp(0);
    $(tab05Ref.current).slideUp(0);
  }, []);
  return (
    <div className="container">
      <div className={classes.mostPopular}>
        <div className={[classes.title, "font-64"].join(" ")}>
          {t("faq_1_title")}
        </div>
        <div className={classes.spoilers}>
          <div
            className={classes.tab}
            onClick={() => $(tab01Ref.current).slideToggle(400)}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>{t("faq_1_item11")}</h3>
            </div>
            <div ref={tab01Ref} className={classes.tabContent}>
              <ul>
                <li> {t("faq_1_item12")}</li>
                <li>{t("faq_1_item13")}</li>
                <li>{t("faq_1_item14")}</li>
              </ul>
            </div>
          </div>
          <div
            className={classes.tab}
            onClick={() => $(tab02Ref.current).slideToggle(400)}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>{t("faq_1_item21")}</h3>
            </div>
            <p ref={tab02Ref} className={classes.tabContent}>
              {t("faq_1_item22")}
            </p>
          </div>
          <div
            className={classes.tab}
            onClick={() => $(tab03Ref.current).slideToggle(400)}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>{t("faq_1_item31")}</h3>
            </div>
            <div ref={tab03Ref} className={classes.tabContent}>
              <p> {t("faq_1_item32")}</p>
              <ul>
                <li>{t("faq_1_item33")}</li>
                <li>{t("faq_1_item34")}</li>
                <li> {t("faq_1_item35")}</li>
              </ul>
            </div>
          </div>
          <div
            className={classes.tab}
            onClick={() => $(tab04Ref.current).slideToggle(400)}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>{t("faq_1_item41")}</h3>
            </div>
            <p ref={tab04Ref} className={classes.tabContent}>
              {t("faq_1_item42")}
            </p>
          </div>
          <div
            className={classes.tab}
            onClick={() => $(tab05Ref.current).slideToggle(400)}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>{t("faq_1_item51")}</h3>
            </div>
            <p ref={tab05Ref} className={classes.tabContent}>
              {t("faq_1_item52")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostPopular;
