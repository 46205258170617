import React from "react";
import classes from "./Banner.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img from "../../../assets/img/InvestmentChoices/banner-img.png";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  return ( 
    <div className={[classes.bannerContainer, "container"].join(" ")}>
      <div className={classes.bannerRow}>
        <div className={classes.bannerContent}>
          <h1 className={[classes.bannerTitle, "font-80"].join(" ")}>
            {t("investment_item1")}
          </h1>
          <p className={[classes.bannerText, "font-32"].join(" ")}>
            {t("investment_item2")}
          </p>
          <Button>{t("start_btn")}</Button>
        </div>
        <div className={classes.bannerImage}>
          <img src={img} alt="Banner" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
