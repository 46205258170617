import React, { useState, useEffect } from "react";
import classes from "./Header.module.scss";
import logo from "../../../assets/img/header/logo.png";
import arrow from "../../../assets/img/header/arrow.svg";
import { headerRoutes } from "../../../router/index";
import { Link, NavLink } from "react-router-dom";
import "./Header.scss";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Cookies from "js-cookie";

const Header = () => {
  const { t } = useTranslation();
  global.text = t;
  const languages = [
    { value: "ru", label: "Ru" },
    { value: "en", label: "En" },
    { value: "es", label: "Es" },
    { value: "it", label: "It" },
    { value: "de", label: "De" },
    { value: "pl", label: "Pl" },
    { value: "ar", label: "Ar" },
  ];
  const [localA, setLocalA] = useState(false);
  const [burgerA, setBurgerA] = useState(false);
  const [defaultOption, setDefaultOption] = useState(Cookies.get("i18next"));

  useEffect(() => {
    setDefaultOption(Cookies.get("i18next"));
  }, []);

  useEffect(() => {
    if ($("body").hasClass("lock")) {
      $("body").removeClass("lock");
    }
  }, []);

  return (
    <div className={classes.header}>
      <div className={classes.headerBody}>
        <div className="container">
          <div className={classes.headerRow}>
            <div className={classes.headerRowLeft}>
              <div
                className={
                  burgerA
                    ? [classes.headerRowLogo, classes.active].join(" ")
                    : classes.headerRowLogo
                }
              >
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>
              <div className={classes.wrap2}>
                {headerRoutes.map((navI) => {
                  if (navI.text === "header_item_4") {
                    return (
                      <NavLink
                        to={navI.path}
                        className={[
                          classes.headerNavLink,
                          `headerNavLink headerNavLink${t("lang")}`,
                        ].join(" ")}
                        key={navI.id}
                      >
                        {t(navI.text)}
                      </NavLink>
                    );
                  } else {
                    return (
                      <NavLink
                        to={navI.path}
                        className={[
                          classes.headerNavLink,
                          "headerNavLink",
                        ].join(" ")}
                        key={navI.id}
                      >
                        {t(navI.text)}
                      </NavLink>
                    );
                  }
                })}
              </div>
            </div>

            <div className={classes.headerRowRight}>
              <div
                className={
                  burgerA
                    ? [classes.headerRowCenterWrapper, classes.active].join(" ")
                    : classes.headerRowCenterWrapper
                }
              >
                <div className={classes.headerRowCenter}>
                  <div className={classes.wrap2Over}>
                    <div className={[classes.wrap2, classes.mob].join(" ")}>
                      {headerRoutes.map((navI) => {
                        if (navI.text === "header_item_4") {
                          return (
                            <NavLink
                              to={navI.path}
                              className={[
                                classes.headerNavLink,
                                `headerNavLink headerNavLink${t("lang")}`,
                              ].join(" ")}
                              key={navI.id}
                            >
                              {t(navI.text)}
                            </NavLink>
                          );
                        } else {
                          return (
                            <NavLink
                              to={navI.path}
                              className={[
                                classes.headerNavLink,
                                "headerNavLink",
                              ].join(" ")}
                              key={navI.id}
                            >
                              {t(navI.text)}
                            </NavLink>
                          );
                        }
                      })}
                      <a
                        className={[
                          classes.headerRowRightSignUpBtn,
                          classes.mob,
                        ].join(" ")}
                        href={`https://client.saberx.net/login?lang=${defaultOption}`}
                      >
                        {t("header_sign")}
                      </a>
                      <a
                        className={[
                          classes.headerRowRightOpenBtn,
                          classes.mob,
                        ].join(" ")}
                        href={`https://client.saberx.net/signup?lang=${defaultOption}`}
                      >
                        <span>{t("open_btn")}</span>
                      </a>
                      {/* <div
                        className={[
                          classes.headerRowRightLocalization,
                          classes.mob,
                        ].join(" ")}
                        onClick={() => {
                          setLocalA(!localA);
                        }}
                      >
                        <div
                          className={
                            localA
                              ? burgerA
                                ? [
                                    classes.headerRowRightLocalizationTitle,
                                    classes.active,
                                    classes.white,
                                  ].join(" ")
                                : [
                                    classes.headerRowRightLocalizationTitle,
                                    classes.active,
                                  ].join(" ")
                              : burgerA
                              ? [
                                  classes.headerRowRightLocalizationTitle,
                                  classes.white,
                                ].join(" ")
                              : classes.headerRowRightLocalizationTitle
                          }
                        >
                          {defaultOption}
                          <img src={arrow} alt="" />
                        </div>
                        <div
                          className={
                            localA
                              ? [
                                  classes.headerRowRightLocalizationItems,
                                  classes.active,
                                ].join(" ")
                              : classes.headerRowRightLocalizationItems
                          }
                        >
                          {languages.map((i) => {
                            return (
                              <p
                                key={i.value}
                                onClick={() => {
                                  i18n.changeLanguage(i.value);
                                  setDefaultOption(i.value);
                                }}
                              >
                                {i.value}
                              </p>
                            );
                          })}
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={classes.headerRowRightLocalization}
                onClick={() => {
                  setLocalA(!localA);
                }}
              >
                <div
                  className={
                    localA
                      ? burgerA
                        ? [
                          classes.headerRowRightLocalizationTitle,
                          classes.active,
                          classes.white,
                        ].join(" ")
                        : [
                          classes.headerRowRightLocalizationTitle,
                          classes.active,
                        ].join(" ")
                      : burgerA
                        ? [
                          classes.headerRowRightLocalizationTitle,
                          classes.white,
                        ].join(" ")
                        : classes.headerRowRightLocalizationTitle
                  }
                >
                  {defaultOption}
                  <img src={arrow} alt="" />
                </div>
                <div
                  className={
                    localA
                      ? [
                        classes.headerRowRightLocalizationItems,
                        classes.active,
                      ].join(" ")
                      : classes.headerRowRightLocalizationItems
                  }
                >
                  {languages.map((i) => {
                    return (
                      <p
                        key={i.value}
                        onClick={() => {
                          i18n.changeLanguage(i.value);
                          setDefaultOption(i.value);
                        }}
                      >
                        {i.value}
                      </p>
                    );
                  })}
                </div>
              </div>
              <a
                href={`https://client.saberx.net/login?lang=${defaultOption}`}
                className={classes.headerRowRightSignUpBtn}
              >
                {t("header_sign")}
              </a>
              <a
                href={`https://client.saberx.net/signup?lang=${defaultOption}`}
                className={classes.headerRowRightOpenBtn}
              >
                <span>{t("open_btn")}</span>
              </a>

              <div
                className={
                  burgerA
                    ? [classes.headerRowRightBurger, classes.active].join(" ")
                    : classes.headerRowRightBurger
                }
                onClick={() => {
                  if ($("body").hasClass("lock")) {
                    $("body").removeClass("lock");
                  } else {
                    $("body").addClass("lock");
                  }
                  setBurgerA(!burgerA);
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
