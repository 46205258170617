import React from "react";
import classes from "./Footer.module.scss";
import img1 from "../../../assets/img/header/logo_footer.png";
import "./Footer.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <div className={classes.footer}>
      <div className={["container", classes.cont].join(" ")}>
        <div className={classes.footerBody}>
          <div className={classes.footerTop}>
            <div className={classes.footerTopLeft}>
              <h2 className={[classes.footerTopLeftTitle, "font-64"].join(" ")}>
                {t("footer_top_11")}
              </h2>
              <p
                className={[classes.footerTopLeftSubtitle, "font-32"].join(" ")}
              >
                {t("footer_top_12")}
              </p>
            </div>
            <div
              className={[
                classes.footerTopCenter,
                `footerTopCenter_${t("lang")}`,
              ].join(" ")}
            >
              <p className={[classes.footerTopCenterItem, "font-20"].join(" ")}>
                {t("footer_top_21")}&nbsp;
                <a href="tel:+12894800223">
                  +12894800223
                </a>
              </p>
              <p className={[classes.footerTopCenterItem, "font-20"].join(" ")}>
                {t("footer_top_22")}&nbsp;
                <a href={`mailto:support@${hostname}`}>
                  support@{hostname}
                </a>
              </p>
              <p className={[classes.footerTopCenterItem, "font-20"].join(" ")}>
                {t("footer_top_23")} 76 Rue Saint-Paul, Québec, QC G1K 3V9
              </p>
            </div>
            <div className={classes.footerTopImg}></div>
          </div>
          <div className={classes.footerBgWrap}>
            <div className={classes.footerCenter}>
              <div className={classes.footerCenterLeft}>
                <img src={img1} alt="" />
              </div>
              <div className={classes.footerCenterCenter}>
                <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank" className={classes.footerCenterItem}>
                  {t("footer_center_11")}
                </a>
                <a href="/docs/PRIVACY_POLICY.pdf" target="_blank" className={classes.footerCenterItem}>
                  {t("footer_center_12")}
                </a>
                <a href="/docs/AML_POLICY.pdf" target="_blank" className={classes.footerCenterItem}>
                  {t("footer_center_13")}
                </a>
              </div>
              <div className={classes.footerRight}>
                <div className={classes.footerRightTitle}>
                  {t("footer_center_21")}
                </div>
                <div className={classes.footerRightText}>
                  {t("footer_center_22")}
                </div>
                <div className={classes.footerRightText}>
                  {t("footer_center_23")}
                </div>
              </div>
            </div>
            <div className={classes.footerBottom}>
              <div className={classes.footerBottomCopyright}>
                {t("footer_bottom_1")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
