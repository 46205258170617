import React from "react";
import { useState } from "react";
import { Trans } from "react-i18next";
import classes from "./DetailedPricing.module.scss";

import { useTranslation } from "react-i18next";

const DetailedPricing = () => {
  const { t } = useTranslation();

  const tabs = {
    1: {
      header: [
        "pricing_2_item1_top1",
        "pricing_2_item1_top2",
        "pricing_2_item1_top3",
      ],
      body: [
        ["pricing_2_item1_content1", "$0", "$0"],
        ["pricing_2_item1_content2", "$0.65", "$0.50"],
        ["pricing_2_item1_content3", "$0", "$0"],
      ],
    },
    2: {
      header: [
        "pricing_2_item2_top1",
        "pricing_2_item2_top2",
        "pricing_2_item2_top3",
      ],
      body: [["ETFs", "$0", "$0"]],
    },
    3: {
      body: [["pricing_2_item3_content1", "pricing_2_item3_content2"]],
    },
    4: {
      header: [
        "pricing_2_item4_top1",
        "pricing_2_item4_top2",
        "pricing_2_item4_top3",
      ],
      body: [
        ["SPX", "pricing_2_item4_content1", "$0.51"],
        ["RUT", "pricing_2_item4_content2", "$0.18"],
        ["VIX", "pricing_2_item4_content3", "$0.32"],
        ["OEX", "pricing_2_item4_content4", "$0.40"],
        ["XEO", "pricing_2_item4_content5", "$0.40"],
        ["DJX", "pricing_2_item4_content6", "$0.18"],
      ],
    },
  };

  const tabNames = [
    {
      id: 1,
      name: "pricing_2_tab_title_1",
    },
    {
      id: 2,
      name: "pricing_2_tab_title_2",
    },
    {
      id: 3,
      name: "pricing_2_tab_title_3",
    },
    {
      id: 4,
      name: "pricing_2_tab_title_4",
    },
  ];
  const [currentTab, setCurrentTab] = useState(1);
  return (
    <section className={["container", classes.container].join(" ")}>
      <div className={classes.detailedPricing}>
        <h2 className={[classes.title, "font-64"].join(" ")}>
          {t("pricing_2_title")}
        </h2>
        <div className={classes.tabs}>
          <div className={classes.buttons}>
            {tabNames.map((tabName) => (
              <div
                key={tabName.id}
                className={`${classes.btn} ${
                  tabName.id === currentTab && classes.btnActive
                }`}
                onClick={() => setCurrentTab(tabName.id)}
              >
                <span>{t(tabName.name)}</span>
              </div>
            ))}
          </div>
          <div className={classes.content}>
            <div className={classes.tableWr}>
              <table>
                <tbody>
                  {tabs[currentTab].header && (
                    <tr className={classes.headerRow}>
                      {tabs[currentTab].header.map(
                        (headerTd, headerTdIndex) => (
                          <td key={headerTdIndex}>
                            <Trans>{t(headerTd)}</Trans>
                          </td>
                        )
                      )}
                    </tr>
                  )}
                  {tabs[currentTab].body.map((row, rowIndex) => (
                    <tr
                      className={`${rowIndex % 2 === 0 && classes.darkTr}`}
                      key={rowIndex}
                    >
                      {row.map((rowTd, rowTdIndex) => (
                        <td key={rowTdIndex}>
                          <Trans components={{ span: <span></span> }}>
                            {t(rowTd)}
                          </Trans>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DetailedPricing;
