import logo from '../assets/img/header/logo.png';
// main page
import laptop from '../assets/img/MainPage/Banner/laptop.png';
import graph from '../assets/img/MainPage/Banner/graph.png';
import sphere from '../assets/img/MainPage/Banner/sphere.png';
import money_01_left from '../assets/img/MainPage/Banner/money_01_left.png';
import money_02_left from '../assets/img/MainPage/Banner/money_02_left.png';
import money_03_left from '../assets/img/MainPage/Banner/money_03_left.png';
import money_04_right from '../assets/img/MainPage/Banner/money_04_right.png';
import money_05_right from '../assets/img/MainPage/Banner/money_05_right.png';
import money_06_right from '../assets/img/MainPage/Banner/money_06_right.png';
import image_01 from '../assets/img/MainPage/TheValue/image_01.png';
import image_02 from '../assets/img/MainPage/TheValue/image_02.png';
// quick start 
import img1 from "../assets/img/QuickStart/1/1.png";
import img2 from "../assets/img/QuickStart/1/2.png";
import img3 from "../assets/img/QuickStart/1/3.png";
// investment choices
import img22 from "../assets/img/InvestmentChoices/banner-img.png"
// pricing
import image from '../assets/img/PricingPage/banner.png';
// about page
import image_011 from "../assets/img/AboutPage/1.png";
import image_021 from "../assets/img/AboutPage/2.png";

export const images = [
    logo,
    laptop,
    graph,
    sphere,
    money_01_left,
    money_02_left,
    money_03_left,
    money_04_right,
    money_05_right,
    money_06_right,
    image_01,
    image_02,
    img1,
    img2,
    img3,
    image,
    image_011,
    image_021,
    img22,
];