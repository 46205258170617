import React, { useEffect } from "react";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import TheValue from "../../components/AboutPageComponents/TheValue/TheValue";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <TheValue />
      <Footer />
    </div>
  );
};

export default AboutPage;
