import React from "react";
import classes from "./OurInstruments.module.scss";
import icon_01 from "../../../assets/img/MainPage/OurInstruments/icon_01.svg";
import icon_02 from "../../../assets/img/MainPage/OurInstruments/icon_02.svg";
import icon_03 from "../../../assets/img/MainPage/OurInstruments/icon_03.svg";
import icon_04 from "../../../assets/img/MainPage/OurInstruments/icon_04.svg";
import { useRef } from "react";
import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const OurInstruments = () => {
  const { t } = useTranslation();
  const our_instruments_ref = useRef(null);
  const card_ref_01 = useRef(null);
  const card_ref_02 = useRef(null);
  const card_ref_03 = useRef(null);
  const card_ref_04 = useRef(null);
  const cards = [
    {
      id: 1,
      ref: card_ref_01,
      icon: icon_01,
      title: "main_4_item11",
      text: "main_4_item12",
    },
    {
      id: 2,
      ref: card_ref_02,
      icon: icon_02,
      title: "main_4_item21",
      text: "main_4_item22",
    },
    {
      id: 3,
      ref: card_ref_03,
      icon: icon_03,
      title: "main_4_item31",
      text: "main_4_item32",
    },
    {
      id: 4,
      ref: card_ref_04,
      icon: icon_04,
      title: "main_4_item41",
      text: "main_4_item42",
    },
  ];
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      setTimeout(() => {
          gsap.timeline().fromTo(
            card_ref_01.current,
            {
              y: 200,
            },
            {
              y: 0,
              ease: "none",
              scrollTrigger: {
                trigger: our_instruments_ref.current,
                end: "+=200px",
                scrub: 1,
              },
            }
          );
          gsap.timeline().fromTo(
            card_ref_02.current,
            {
              y: 300,
            },
            {
              y: 0,
              ease: "none",
              scrollTrigger: {
                trigger: our_instruments_ref.current,
                end: "+=200px",
                scrub: 1,
              },
            }
          );
          gsap.timeline().fromTo(
            card_ref_03.current,
            {
              y: 400,
            },
            {
              y: 0,
              ease: "none",
              scrollTrigger: {
                trigger: our_instruments_ref.current,
                end: "+=200px",
                scrub: 1,
              },
            }
          );
          gsap.timeline().fromTo(
            card_ref_04.current,
            {
              y: 500,
            },
            {
              y: 0,
              ease: "none",
              scrollTrigger: {
                trigger: our_instruments_ref.current,
                end: "+=200px",
                scrub: 1,
              },
            }
          );
      }, 500);
    }
  }, []);
  return (
    <section ref={our_instruments_ref} className={classes.ourInstruments}>
      <div className="container">
        <h2 className={[classes.title, "font-64"].join(" ")}>
          {t("main_4_title")}
        </h2>
      </div>
      <div className={[classes.container, "container"].join(" ")}>
        <div className={classes.cards}>
          {cards.map((card) => (
            <div ref={card.ref} key={card.id} className={classes.card}>
              <div className={classes.cardContent}>
                <img
                  className={[classes.cardImage, "no-select"].join(" ")}
                  src={card.icon}
                  alt=""
                />
                <h3 className={[classes.cardTitle, "font-32"].join(" ")}>
                  {t(card.title)}
                </h3>
                <p className={[classes.cardText, "font-20"].join(" ")}>
                  {t(card.text)}
                </p>
              </div>
              <a href="https://client.saberx.net/signup" className={classes.learnMore}>
                {t("learnmore")}
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurInstruments;
